body {
    font-family: sans-serif;
    background: #1a1a1a;
  }
  
  form {
    margin: 5rem auto 0;
    width: 24rem;
  
    input {
      margin-bottom: 1.5rem;
      padding: 0 0.5rem;
      width: inherit;
      height: 2rem;
      border: none;
      border-radius: 4px;
      box-sizing: border-box;
    }
  
    input[type="submit"] {
      height: 2.5rem;
      font-size: 1rem;
      color: #fff;
      background: #0971f1;
      cursor: pointer;
    }
    select {
      margin-bottom: 1.5rem;
      padding: 0 0.5rem;
      width: inherit;
      height: 2rem;
      border: none;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
  